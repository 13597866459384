import React from 'react'
import PropTypes from 'prop-types'
import useAuthority from 'utils/hooks/useAuthority'

const AuthorityCheck = props => {

    const {userAuthority = [], authority = [], systemLevel, routeLevel, children} = props

    const roleMatched = useAuthority(userAuthority, authority, systemLevel, routeLevel)

    return roleMatched ? children : <></>
}

AuthorityCheck.propTypes = {
    userAuthority: PropTypes.array,
    authority: PropTypes.array,
    systemLevel: PropTypes.string,
    routeLevel: PropTypes.string
}

export default AuthorityCheck
