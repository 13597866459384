import React, {createContext} from 'react'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {PersistGate} from 'redux-persist/integration/react'
import store, {persistor} from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import appConfig from 'configs/app.config'
import './locales'
import {PERSIST_STORE_NAME} from "./constants/app.constant";
import deepParseJson from "./utils/deepParseJson";
import ErrorBoundary from "./ErrorBoundary"

const environment = process.env.NODE_ENV

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
if (environment !== 'production' && appConfig.enableMock) {
    console.log({environment})
}

export const PermContext = createContext(null);

function App() {
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    const persistData = deepParseJson(rawPersistData)
    const permissions = persistData?.auth?.session?.permissions;

    return (
        <ErrorBoundary>
            <Provider store={store}>
                <PermContext.Provider value={{permissions}}>
                    <PersistGate loading={null} persistor={persistor}>
                        <BrowserRouter history={history}>
                            <Theme>
                                <Layout/>
                            </Theme>
                        </BrowserRouter>
                    </PersistGate>
                </PermContext.Provider>
            </Provider>
        </ErrorBoundary>
    )
}

export default App
