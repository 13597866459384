import {useSelector, useDispatch} from 'react-redux'
import {setUser, initialState} from 'store/auth/userSlice'
import {apiChangePassword, apiSignIn, apiSignUp, apiUpdateProfile} from 'services/auth/AuthService'
import {onSignInSuccess, onSignOutSuccess} from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import {REDIRECT_URL_KEY} from 'constants/app.constant'
import {useNavigate} from 'react-router-dom'
import useQuery from './useQuery'

function useAuth() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const {token, signedIn, systemLevel, username} = useSelector((state) => state.auth.session)

    const changePassword = async (values) => {
        const setSubmitting = values['setSubmitting']
        try {
            const response = await apiChangePassword(values);
            if (response.status === 200) {
                dispatch(onSignOutSuccess())
                return response
            }
        } catch (errors) {
            return errors.response
        }
        setSubmitting(false)
    }

    const updateProfile = async (values) => {
        const setSubmitting = values['setSubmitting']
        try {
            const response = await apiUpdateProfile(values);
            if (response.data) {
                dispatch(setUser(response.data || {
                    avatar: '',
                    userName: 'Anonymous',
                    authority: [],
                    email: ''
                }))
                return response
            }
        } catch (errors) {
            return errors.response
        }
        setSubmitting(false)
    }

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values);
            if (resp.data) {
                dispatch(onSignInSuccess(resp.data))
                dispatch(setUser(resp.data || {
                    avatar: '',
                    userName: 'Anonymous',
                    authority: [],
                    email: ''
                }))
                if (resp?.data?.system_level === 'Developer') {
                    navigate(appConfig.authenticatedDeveloperEntryPath)
                } else if (resp?.data?.system_level === 'Business') {
                    navigate(appConfig.authenticatedBusinessEntryPath)
                } else {
                    navigate(appConfig.authenticatedGuestEntryPath)
                }

                window.location.reload()
                return {
                    status: 'success',
                    message: 'Welcome back!'
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.detail || errors.toString()
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const {token} = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(setUser(resp.data.user || {
                        avatar: '',
                        userName: 'Anonymous',
                        authority: ['USER'],
                        email: ''
                    }))
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
                return {
                    status: 'success',
                    message: ''
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        // await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        systemLevel: systemLevel,
        username,
        signIn,
        signUp,
        signOut,
        updateProfile,
        changePassword
    }
}

export default useAuth
