import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    currentRouteKey: '',
    linearLoading: false,
}

export const commonSlice = createSlice({
	name: 'base/common',
	initialState,
	reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        },
        setLoading: (state, action) => {
            state.linearLoading = action.payload
        }
	},
})

export const { setCurrentRouteKey, setLoading } = commonSlice.actions

export default commonSlice.reducer
