import axios from 'axios'
import appConfig from 'configs/app.config'
import {TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY} from 'constants/api.constant'
import {PERSIST_STORE_NAME} from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../../store'
import {onSignOutSuccess} from '../../store/auth/sessionSlice'
import {Notification, toast} from "../../components/ui";

const unauthorizedCode = [401]

const BaseService = axios.create({
    timeout: 120000,
    baseURL: appConfig.apiPrefix,
})

BaseService.interceptors.request.use(config => {

    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    const persistData = deepParseJson(rawPersistData)
    const exportingExcel = localStorage.getItem('exportingExcel')

    const accessToken = persistData.auth.session.token
    if (accessToken) {
        config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`
    }
    if (exportingExcel === true || exportingExcel === "true") {
        config['responseType'] = 'blob'
    }

    return config
}, error => {
    return Promise.reject(error)
})

BaseService.interceptors.response.use(
    response => response,
    error => {

        const {response} = error

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess())
            if (response?.data?.detail !== "Invalid login credentials. Please try again.") {
                toast.push(
                    <Notification title={'Info'} type="info" duration={15000}>
                        {'Session timed out. Login in again'}
                    </Notification>
                    , {
                        placement: 'top-end'
                    }
                )
            }
        }

        return Promise.reject(error)
    }
)

export default BaseService
