import {createSlice} from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: '',
        refresh: null,
        useType: null,
        systemLevel: null,
        username: null,
        userId: null,
        businessesAccess: [],
        recentlyAccessedSchool: {},
        permissions: [],
        signedIn: false,
        businessName: null,
        businessId: null
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            // const decodedToken = jwt.verify(action?.payload?.token, process.env.somerandomstring);
            // console.log(decodedToken)
            state.signedIn = true
            state.token = action?.payload?.token
            state.refresh = action?.payload?.refresh
            state.userType = action?.payload?.user_type
            state.systemLevel = action?.payload?.system_level
            state.username = action?.payload?.username
            state.userId = action?.payload?._id
            state.permissions = action?.payload?.permissions
            state.businessesAccess = action?.payload?.business_access
            state.businessName = action.payload?.business_name
            state.businessId = action.payload?.business_id
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = null
            state.refresh = null
            state.userType = null
            state.systemLevel = null
            state.username = null
            state.userId = null
            state.permissions = []
            state.businessesAccess = []
            state.businessName = null
            state.businessId = null
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setCompanySession: (state, action) => {
            state.signedIn = true
            state.token = action.payload.token
            state.userType = action.payload.user_type
            state.systemLevel = action.payload.system_level
            state.username = action.payload.username
            state.permissions = action.payload.permissions
            state.businessesAccess = action.payload.business_access
            state.businessName = action.payload.business_name
            state.businessId = action.payload.business_id
        },
        setSchoolSession: (state, action) => {
            state.signedIn = true
            state.token = action.payload.token
            state.userType = action.payload.user_type
            state.systemLevel = action.payload.system_level
            state.username = action.payload.username
            state.permissions = action.payload.permissions
            state.businessesAccess = action.payload.business_access
            state.recentlyAccessedSchool = action.payload.recently_accessed_school
            state.businessName = action.payload.business_name
            state.businessId = action.payload.business_id
        },
        setDeveloperSession: (state, action) => {
            state.signedIn = true
            state.token = action.payload.token
            state.userType = action.payload.user_type
            state.systemLevel = action.payload.system_level
            state.username = action.payload.username
            state.permissions = action.payload.permissions
            state.businessesAccess = action.payload.business_access
            state.businessName = action.payload.business_name
            state.businessId = action.payload.business_id
        }
    },
})

export const {
    onSignInSuccess,
    onSignOutSuccess,
    setToken,
    setCompanySession,
    setDeveloperSession,
    setSchoolSession
} = sessionSlice.actions

export default sessionSlice.reducer
