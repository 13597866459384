import {useMemo} from 'react'
import isEmpty from 'lodash/isEmpty'

function useAuthority(userAuthority = [], authority = [], systemLevel = null, routeLevel = null, emptyCheck = false) {

    const roleMatched = useMemo(() => {
        const matched = authority.some(role => userAuthority.includes(role))
        return matched && (systemLevel === routeLevel)
    }, [authority, userAuthority, systemLevel, routeLevel])

    if (isEmpty(authority) || isEmpty(userAuthority) || typeof authority === 'undefined' || systemLevel === null || routeLevel === null) {
        return !emptyCheck
    }

    return roleMatched
}

export default useAuthority
