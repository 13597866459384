import React from "react";
import {apiSendErrorReport} from "./services/errors";


export default class FallbackUI extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: props?.error,
            errorInfo: props?.errorInfo,
            sent: false
        }
    }

    componentDidMount() {
        const payload = {
            column_number: this.state.error?.columnNumber,
            file_name: this.state.error?.fileName || this.state.error?.filename,
            line_number: this.state.error?.lineNumber,
            message: this.state.error?.message
        }
        if (this.state.sent === false) {
            apiSendErrorReport(payload)
            this.setState({
                error: this.state?.error,
                errorInfo: this.state?.errorInfo,
                sent: true
            })
        }
    }

    render() {
        return (
            <div className="max-w-lg mx-auto mt-8 p-4 rounded-md border border-red-500 bg-red-100 text-red-700">
                <h2 className="text-lg font-bold mb-2">Something went wrong!</h2>
                <p className="mb-4">Please try refreshing the page or come back later.</p>
                <ul>
                    <li>
                        <strong>Column Number:</strong> {this.state.error?.columnNumber}
                    </li>
                    <li>
                        <strong>Filename:</strong> {this.state.error?.fileName || this.state.error?.filename}
                    </li>
                    <li>
                        <strong>Line Number:</strong> {this.state.error?.lineNumber}
                    </li>
                    <li>
                        <strong>Message:</strong> {this.state.error?.message}
                    </li>
                </ul>
                <p className="mt-4">
                    <em>If the problem persists contact our team: <strong>+255 762 547 915</strong></em>
                </p>
            </div>
        )
    }
}
