import React from "react";
import FallbackUI from "./FallbackUI";

export default class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null}
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        if (this.state.errorInfo) {
            return <FallbackUI {...{errorInfo: this.state.errorInfo, error: this.state.error}}/>
        }

        return this.props.children;
    }
}
