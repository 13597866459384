const appConfig = {
    // scp -r ./build/* root@139.59.63.219:/var/www/hrms/
    apiPrefix: 'https://api.hrms.co.tz',
    // apiPrefix: 'http://localhost:8000',
    authenticatedEntryPath: '/company',
    // authenticatedDeveloperEntryPath: '/developer',
    authenticatedDeveloperEntryPath: '/businesses/list',
    // authenticatedCompanyEntryPath: '/company',
    authenticatedBusinessEntryPath: '/logbook',
    authenticatedSchoolEntryPath: '/school',
    authenticatedGuestEntryPath: '/guest',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    enableMock: false
}

export default appConfig
