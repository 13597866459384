import {createSlice} from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    userName: '',
    id: '',
    email: '',
    userType: '',
    systemLevel: '',
    businessesAccess: '',
    companyName: '',
    companyId: '',
    schoolName: '',
    schoolPhone: '',
    schoolId: '',
    authority: [],
    token: ''
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.avatar = ''
            state.id = action.payload._id
            state.userName = action.payload.username
            state.email = action.payload.email
            state.authority = action.payload.permissions
            state.businessesAccess = action.payload.business_access
            state.systemLevel = action.payload.system_level
            state.userType = action.payload.user_type
            state.companyName = action.payload.company_name
            state.companyId = action.payload.company_id
            state.schoolName = action.payload.school_name
            state.schoolPhone = action.payload.school_phone_number
            state.schoolId = action.payload.school_id
            state.token = action.payload.token
        },
        userLoggedOut: () => initialState,
    },
})

export const {setUser} = userSlice.actions

export default userSlice.reducer
