import ApiService from '../base/ApiService'

export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: '/auth/token/',
        method: 'post',
        data
    })
}

export async function apiUpdateProfile (data) {
    return ApiService.fetchData({
        url: '/users/change-accounts-details/',
        method: 'post',
        data
    })
}

export async function apiChangePassword (data) {
    return ApiService.fetchData({
        url: '/auth/change-password/',
        method: 'post',
        data
    })
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data
    })
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data
    })
}
