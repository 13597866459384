import BaseService from './BaseService'
import {Notification, toast} from "../../components/ui";
import {setLoading} from "../../store/base/commonSlice";
import store from "../../store";

const ApiService = {
    fetchData(param) {
        store.dispatch(setLoading(true))
        return new Promise((resolve, reject) => {
            BaseService(param).then(response => {
                resolve(response)
                store.dispatch(setLoading(false))
            }).catch(errors => {
                localStorage.setItem('exportingExcel', false) // @todo -> Come back later for review. Temporary solution
                if ([500, 400].includes(errors?.status) || errors?.message === "Network Error") {
                    toast.push(
                        <Notification title={errors?.response?.statusText} type="danger" duration={10000}>
                            <span>Unable to connect to servers, something went wrong on our end, please try again.</span>
                        </Notification>
                        , {
                            placement: 'top-start'
                        }
                    )
                }
                reject(errors)
            })
        })
    }
}

export default ApiService
